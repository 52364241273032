import {
  Box,
  FormControl,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { Control, Controller } from "react-hook-form";

interface Props {
  name: string;
  control: Control<any, any>;
  sx?: SxProps;
}

function SexInput({ name, control, sx }: Props) {
  return (
    <FormControl sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup
            fullWidth
            size="large"
            value={field.value}
            exclusive
            onChange={(_, newAlignment: string) => {
              if (newAlignment !== null) {
                field.onChange(newAlignment);
              }
            }}
          >
            <ToggleButton value={true} color="female">
              <WomanIcon />
              <Typography>Femme</Typography>
            </ToggleButton>
            <ToggleButton value={false} color="male">
              <Typography>Homme</Typography>
              <ManIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      />
    </FormControl>
  );
}

export default SexInput;
