import { Box, Button, IconButton, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useError from "../../hooks/error";
import { useNavigate, useParams } from "react-router-dom";
import Member from "../../models/Member";
import OnboardingService from "../../services/Onboarding";
import { confirmAlert } from 'react-confirm-alert';
import '../../confirm-alert/css/react-confirm-alert.css';

function MemberDetails() {
  const { t } = useTranslation();
  const { setError } = useError();
  const navigate = useNavigate();
  const { id: coopId, memberId } = useParams();
  const [member, setMember] = useState<Member>();

  const handleReturn = () => {
    navigate(`/coops/${coopId}`);
  };

  const handleEdit = () => {
    navigate(`/coops/${coopId}/onboarding/${memberId}`);
  };

  const handleDelete = () => {
    confirmAlert({
      title: t('listing.delete.confirmation.title') ?? "",
      message: t('listing.delete.confirmation.text') ?? "",
      buttons: [
        {
          label: t('listing.delete.confirmation.yes') ?? "",
          onClick: () => {
            if (memberId) {
              OnboardingService.deleteMember(memberId).then(handleReturn).catch(setError);
            }
          }
        },
        {
          label: t('listing.delete.confirmation.no') ?? "",
          onClick: () => {}
        }
      ]
    });
  };

  useEffect(() => {
    if (memberId) {
      OnboardingService.getMember(memberId)
        .then(setMember)
        .catch(setError);
    }
  }, [memberId, setError]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          px: (theme) => theme.spacing(2),
          boxSizing: "border-box",
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {t("onboarding.titles.member")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.firstname")} :
          </Typography>
          <Typography>{member?.firstname}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.lastname")} :
          </Typography>
          <Typography>{member?.lastname}</Typography>
        </Box>
        {member?.nationalIdentityNumber && (
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.nationalIdentityNumber")} :
            </Typography>
            <Typography>{member?.nationalIdentityNumber}</Typography>
          </Box>
        )}
        {member?.phone && (
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.phone")} :
            </Typography>
            <Typography>{member?.phone}</Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.numberOfSubscriptions")} :
          </Typography>
          <Typography>{member?.numberOfSubscriptions}</Typography>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mt: "auto",
          borderTop: `1px solid ${theme.palette.divider}`,
          pt: theme.spacing(2),
        })}
      >
        <IconButton color="primary" onClick={handleReturn}>
          <ArrowForwardIcon sx={{ transform: "rotate(180deg)" }} />
        </IconButton>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleEdit}
        >
          {t("listing.edit.member")}
        </Button>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default MemberDetails;
