import { amber, deepPurple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#009036'
    },
    secondary: {
      main: '#663e06'
    },
    background: {
      default: '#F6F6F8',
      paper: '#FFFFFF'
    },
    male: {
      main: amber.A400
    },
    female: {
      main: deepPurple.A400
    }
  }
});

declare module "@mui/material/styles" {
  interface Palette {
    male: Palette["primary"];
    female: Palette["primary"];
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    male?: PaletteOptions["primary"];
    female?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/ToggleButton" {
  interface ToggleButtonPropsColorOverrides {
    male: true;
    female: true;
  }
}

export default theme;
