import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Member from "../../../models/Member";

function OnboardingConfirmation() {
  const { t } = useTranslation();
  const { watch } = useFormContext<Member>();
  const watchLastname = watch("lastname", "");
  const watchFirstname = watch("firstname", "");



  return (
    <>
      <Typography>{t('onboarding.texts.confirmation.member', { lastname: watchLastname, firstname: watchFirstname })}</Typography>
    </>
  );
}

export default OnboardingConfirmation;