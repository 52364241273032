import { FormControl, FormHelperText, SxProps } from "@mui/material";
import { Control, Controller, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput2 from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import theme from "../theme";

interface Props {
  name: string,
  control: Control<any, any>,
  error: FieldError | undefined,
  label: string,
  required?: boolean,
  sx?: SxProps
};

function PhoneInput({ name, control, error, label, required = false, sx }: Props) {
  const { t } = useTranslation();

  return (
    <FormControl sx={{
      ...sx,
      '> .react-tel-input .form-control': {
        '&:focus': {
          boxShadow: `0 0 0 1px ${(error !== undefined) ? theme.palette.error.main : theme.palette.primary.main}`,
          borderColor: (error !== undefined) ? theme.palette.error.main : theme.palette.primary.main
        }
      },
      '> .react-tel-input': {
        color: (error !== undefined) ? theme.palette.error.main : theme.palette.text.secondary,
        '&:focus-within': {
          color: (error !== undefined) ? theme.palette.error.main : theme.palette.primary.main
        }
      }
    }}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: required,
            message: t('errors.fields.required')
          }
        }}
        render={({ field, fieldState: { error } }) =>
          <PhoneInput2
            {...field}
            country={'sn'}
            specialLabel={label}
            inputStyle={{
              width: '100%',
              borderColor: error !== undefined ? theme.palette.error.main : undefined,
              color: error !== undefined ? theme.palette.error.main : undefined
            }}
          />
        }
      />
      {required && error?.message && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default PhoneInput;
