import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Coop from "../../../models/Coop";

interface Props {
  districts: string[]
};

function OnboardingCoverCommune({ districts }: Props) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<Coop>();

  return (
    <Box>
      <Typography variant="body1" align="center" sx={{ fontWeight: 'bold', mb: 1 }}>{t('onboarding.titles.cover')}</Typography>
      <Typography>{t('onboarding.texts.cover')}</Typography>
      <FormControl sx={{ mt: 2, mb: 1, width: "100%" }}>
        <InputLabel id="commune-lable">
          {t("onboarding.fields.commune")} *
        </InputLabel>
        <Controller
          name="coveredCommune"
          rules={{
            required: {
              value: true,
              message: t("errors.fields.required"),
            },
          }}
          defaultValue=""
          control={control}
          render={({ field, formState: { errors } }) => (
            <Select
              fullWidth
              variant="outlined"
              labelId="commune-label"
              label={`${t("onboarding.fields.commune")} *`}
              {...field}
              error={errors?.coveredCommune !== undefined}
              required
            >
              {districts.map((dist) => (
                <MenuItem key={dist} value={dist}>
                  {dist}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors?.coveredCommune?.message && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            {errors?.coveredCommune?.message}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}

export default OnboardingCoverCommune;
