import { useState } from 'react';
import { Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuth from "../hooks/auth";
import { useTranslation } from 'react-i18next';
import LanguageSelector from './Language.selector';

function Layout() {
  const [open, setOpen] = useState(false);
  const { user, onLogout } = useAuth();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    onLogout();
    navigation('');
  };

  const handleHome = () => {
    navigation('');
    setOpen(false);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}>
      <Box sx={{
        width: '100%',
        height: (theme) => theme.spacing(6),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        pd: 0,
        boxSizing: 'border-box',
        bgcolor: 'primary.main',
        boxShadow: 3
      }}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          sx={{ color: 'background.default' }}
        >
          <MenuIcon />
        </IconButton>
        <LanguageSelector darkMode='true' />
      </Box>
      <Drawer
        anchor='left'
        open={open}
        ModalProps={{ onBackdropClick: handleDrawerClose }}
      >
        <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>{user}</Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />
        <List sx={{ height: '100%' }}>
          <ListItemButton
            onClick={handleLogout}
          >
            <ListItemText primary={t('menu.logout')} />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Typography variant="subtitle2" color="gray">Powered by IBISA</Typography>
        </Box>
      </Drawer>
      <Box sx={{
        p: 2,
        height: (theme) => `calc(100% - ${theme.spacing(6)})`,
        width: '100%',
        maxWidth: '600px',
        alignSelf: 'center',
        boxSizing: 'border-box'
      }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;