import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import OnboardingCoopInfo from "./steps/Onboarding.coop.info";
import OnboardingConfirmation from "./steps/Onboarding.confirmation";
import OnboardingService from "../../services/Onboarding";
import { useNavigate, useParams } from "react-router-dom";
import useError from "../../hooks/error";
import districtsConfig from "../../config/districts.config.json";
import communesConfig from "../../config/communes.config.json";
import priceConfig from "../../config/price.config.json";
import Coop from "../../models/Coop";
import OnboardingCoverCommune from "./steps/Onboarding.cover.commune";

const defaultCoop: Coop = {
  name: '',
  district: '',
  coveredCommune: '',
  contact: {
    firstname: '',
    lastname: '',
    email: undefined,
    phone: null
  },
  members: [],
  price: 0,
};

function OnboardingForm() {
  const { t } = useTranslation();
  const { setError } = useError();
  const navigate = useNavigate();
  const { id: coopId } = useParams();
  const [step, setStep] = useState(0);
  const methods = useForm<Coop>({defaultValues: defaultCoop});
  const { handleSubmit, formState, reset } = methods;
  const [loading, setLoading] = useState(false);

  const lastStep = 2;

  const onSubmit: SubmitHandler<Coop> = (data) => {
    setLoading(true);
    data.price = data.members ? data.members.length * priceConfig.price : 0;
    (coopId
      ? OnboardingService.updateCoop(data)
      : OnboardingService.saveCoop(data)
    )
      .then(() => {
        setStep(lastStep);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNext: SubmitHandler<Coop> = (data) => {
    if (Object.values(formState.errors).length === 0) {
      setStep((s) => s + 1);
    }
  };
  const handlePrevious = () => {
    setStep((s) => (s > 0 ? s - 1 : 0));
  };

  const handleCloseBackdrop = () => {
    setLoading(false);
  };

  const handleHome = () => {
    if (coopId) {
      navigate(`/coops/${coopId}`);
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    if (coopId) {
      OnboardingService.getCoop(coopId)
        .then((res) => {
          reset(res);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [coopId, reset, setError]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <FormProvider {...methods}>
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
              px: (theme) => theme.spacing(3),
            }}
          >
            {step === 0 && (
              <OnboardingCoopInfo
                districts={districtsConfig.map((d) => d.name)}
              />
            )}
            {step === 1 && (
              <OnboardingCoverCommune
                districts={communesConfig.map((d) => d.name)}
              />
            )}
            {step === lastStep && <OnboardingConfirmation />}
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: step < lastStep ? "space-between" : "center",
              width: "100%",
              mt: "auto",
              borderTop: `1px solid ${theme.palette.divider}`,
              pt: theme.spacing(2),
            })}
          >
            {step <= lastStep - 1 && (
              <IconButton
                color="primary"
                onClick={handlePrevious}
                disabled={step === 0}
              >
                <ArrowForwardIcon sx={{ transform: "rotate(180deg)" }} />
              </IconButton>
            )}
            {step < lastStep - 1 && (
              <Button
                type="button"
                variant="contained"
                onClick={handleSubmit(handleNext)}
              >
                {t("onboarding.buttons.next")}
              </Button>
            )}
            {step === lastStep - 1 && (
              <Button type="submit" variant="contained">
                {t("onboarding.buttons.submit")}
              </Button>
            )}
            {step <= lastStep - 1 && (
              <IconButton color="error" onClick={handleHome}>
                <CloseIcon />
              </IconButton>
            )}
            {step === lastStep && (
              <Button type="button" variant="contained" onClick={handleHome}>
                {t("onboarding.buttons.home")}
              </Button>
            )}
          </Box>
        </FormProvider>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default OnboardingForm;
