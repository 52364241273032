import { Alert, Snackbar } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import useAuth from "./hooks/auth";
import useError from "./hooks/error";
import Login from "./pages/authentication/Login";
import CoopsList from "./pages/listing/Coops.list";
import OnboardingForm from "./pages/onboarding-coop/Onboarding.form";
import OnboardingMemberForm from "./pages/onboarding-member/Onboarding.member.form";
import ActivationConfig from "./config/activation.config.json";
import CoopDetails from "./pages/listing/Coop.details";
import MemberDetails from "./pages/listing/Member.details";

const Child = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const { error, setError } = useError();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError(null);
  };

  return (
    <>
      <Routes>
        {!isAuthenticated && (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
        {isAuthenticated && (
          <>
            <Route element={<Layout />}>
              {ActivationConfig.onboarding.isActif && (
                <Route path="/onboarding" element={<OnboardingForm />} />
              )}
              <Route path="/onboarding/:id" element={<OnboardingForm />} />
              <Route path="/coops" element={<CoopsList />} />
              <Route path="/coops/:id" element={<CoopDetails />} />
              <Route path="/coops/:id/members/:memberId" element={<MemberDetails />} />
              {ActivationConfig.onboarding.isActif && (
                <Route path="/coops/:id/onboarding" element={<OnboardingMemberForm />} />
              )}
              <Route path="/coops/:id/onboarding/:memberId" element={<OnboardingMemberForm />} />
            </Route>
            <Route path="*" element={<Navigate to="/coops" replace />} />
          </>
        )}
      </Routes>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error !== null && t(`errors.${error}`)}
        </Alert>
      </Snackbar>
    </>
  );
};

const Router = () => {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      <Child {...{ isAuthenticated }} />
    </BrowserRouter>
  );
};

export default Router;
