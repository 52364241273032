import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PhoneInput from "../../../components/Phone.input";
import { Controller, useFormContext } from "react-hook-form";
import Member from "../../../models/Member";
import SexInput from "../../../components/Sex.input";
import priceConfig from "../../../config/price.config.json";

function OnboardingMemberInfo() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getFieldState,
    watch,
  } = useFormContext<Member>();

  const numberOfSubscriptionsWatch = watch("numberOfSubscriptions");

  return (
    <>
      <Controller
        name="firstname"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("errors.fields.required"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.firstname")}
            sx={{ mt: 2, mb: 1 }}
            required
            error={errors?.firstname !== undefined}
            helperText={errors?.firstname?.message}
          />
        )}
      />
      <Controller
        name="lastname"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("errors.fields.required"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.lastname")}
            sx={{ mt: 1, mb: 1 }}
            required
            error={errors?.lastname !== undefined}
            helperText={errors?.lastname?.message}
          />
        )}
      />
      <SexInput
        name={`isWoman`}
        control={control}
        sx={{ mt: 1, mb: 1, width: "100%" }}
      />
      <Controller
        name="nationalIdentityNumber"
        control={control}
        rules={{
          pattern: {
            value: /[0-9]{15}/i,
            message: t("errors.fields.invalid.nationalIdentityNumber"),
          },
          minLength: {
            value: 15,
            message: t("errors.fields.invalid.nationalIdentityNumber"),
          },
          maxLength: {
            value: 15,
            message: t("errors.fields.invalid.nationalIdentityNumber"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.nationalIdentityNumber")}
            sx={{ mt: 1, mb: 1 }}
            error={errors?.nationalIdentityNumber !== undefined}
            helperText={errors?.nationalIdentityNumber?.message}
          />
        )}
      />
      <PhoneInput
        name={`phone`}
        control={control}
        error={getFieldState("phone").error}
        label={t("onboarding.fields.phone")}
        required={false}
        sx={{ mt: 1, mb: 2, width: "100%" }}
      />
      <Controller
        name="numberOfSubscriptions"
        control={control}
        rules={{
          min: {
            value: 1,
            message: t("errors.fields.invalid.numberOfSubscriptions"),
          },
          required: {
            value: true,
            message: t("errors.fields.required"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            required
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.numberOfSubscriptions")}
            sx={{ mt: 1, mb: 1 }}
            error={errors?.numberOfSubscriptions !== undefined}
            helperText={errors?.numberOfSubscriptions?.message}
          />
        )}
      />
      <Typography>
        {t("onboarding.texts.numberOfSubscriptions.price", {
          amount: priceConfig.price * numberOfSubscriptionsWatch,
        })}
      </Typography>
    </>
  );
}

export default OnboardingMemberInfo;
