import {
  Box,
  Button,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import Coop from "../../models/Coop";
import OnboardingService from "../../services/Onboarding";
import { useEffect, useState } from "react";
import priceConfig from "../../config/price.config.json";
import useError from "../../hooks/error";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "../../confirm-alert/css/react-confirm-alert.css";

function CoopDetails() {
  const { t } = useTranslation();
  const { setError } = useError();
  const navigate = useNavigate();
  const { id: coopId } = useParams();
  const [coop, setCoop] = useState<Coop>();

  const handleClickOnboarding = () => {
    navigate(`/coops/${coopId}/onboarding`);
  };

  const handleReturn = () => {
    navigate(`/coops`);
  };

  const handleEdit = () => {
    navigate(`/onboarding/${coopId}`);
  };

  const handleClickMember = (memberId: string | undefined) => () => {
    if (memberId) {
      navigate(`/coops/${coopId}/members/${memberId}`);
    }
  };

  const handleDelete = () => {
    confirmAlert({
      title: t("listing.delete.confirmation.title") ?? "",
      message: t("listing.delete.confirmation.text") ?? "",
      buttons: [
        {
          label: t("listing.delete.confirmation.yes") ?? "",
          onClick: () => {
            if (coopId) {
              OnboardingService.deleteCoop(coopId)
                .then(handleReturn)
                .catch(setError);
            }
          },
        },
        {
          label: t("listing.delete.confirmation.no") ?? "",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (coopId) {
      OnboardingService.getCoop(coopId)
        .then((res) => {
          setCoop(res);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [coopId, setError]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          px: (theme) => theme.spacing(2),
          boxSizing: "border-box",
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {t("onboarding.titles.coop")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.coopName")} :
          </Typography>
          <Typography>{coop?.name}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.district")} :
          </Typography>
          <Typography>{coop?.district}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.numberOfMembers")} :
          </Typography>
          <Typography>{coop?.members.length}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.numberOfSubscriptions")} :
          </Typography>
          <Typography>{coop?.members.reduce((prev, curr) => prev + curr.numberOfSubscriptions, 0)}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.price")} :
          </Typography>
          <Typography>
            {coop?.members.length
              ? priceConfig.price * coop?.members.reduce((prev, curr) => prev + curr.numberOfSubscriptions, 0)
              : 0}{" "}
            CFA
          </Typography>
        </Box>
        <Typography sx={{ mt: 2, fontWeight: "bold" }}>
          {t("onboarding.titles.contact")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.firstname")} :
          </Typography>
          <Typography>{coop?.contact.firstname}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.lastname")} :
          </Typography>
          <Typography>{coop?.contact.lastname}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {t("onboarding.fields.email")} :
          </Typography>
          <Typography>{coop?.contact.email}</Typography>
        </Box>
        {coop?.contact.phone && (
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              {t("onboarding.fields.phone")} :
            </Typography>
            <Typography>{coop?.contact.phone}</Typography>
          </Box>
        )}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {t("onboarding.titles.detailsOfMembers")}
          </Typography>
          <Fab color="secondary" size="small" onClick={handleClickOnboarding} sx={{ p: 0, zIndex: 0 }}>
            <AddIcon />
          </Fab>
        </Box>
        {coop?.members.length === 0 && (
          <Typography>{t("listing.members.none")}</Typography>
        )}
        {coop !== undefined && coop.members.length > 0 && (
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflow: "auto",
              p: 0,
            }}
          >
            {coop?.members.map((field, index) => (
              <ListItem
                sx={{
                  display: "flex",
                  width: "100%",
                  p: 0,
                  boxSizing: "border-box",
                }}
              >
                <ListItemButton
                  onClick={handleClickMember(field.id)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    {field.isWoman ? "Ms." : "Mr."} {field.lastname} {field.firstname}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mt: "auto",
          borderTop: `1px solid ${theme.palette.divider}`,
          pt: theme.spacing(2),
        })}
      >
        <IconButton color="primary" onClick={handleReturn}>
          <ArrowForwardIcon sx={{ transform: "rotate(180deg)" }} />
        </IconButton>
        <Button variant="contained" color="secondary" onClick={handleEdit}>
          {t("listing.edit.coop")}
        </Button>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CoopDetails;
