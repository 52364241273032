import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Member from "../../models/Member";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import OnboardingMemberInfo from "./steps/Onboarding.member.info";
import OnboardingConfirmation from "./steps/Onboarding.confirmation";
import OnboardingService from "../../services/Onboarding";
import useError from "../../hooks/error";

const defaultMember: Member = {
  firstname: "",
  lastname: "",
  isWoman: true,
  nationalIdentityNumber: undefined,
  numberOfSubscriptions: 1,
  phone: "",
};

function OnboardingMemberForm() {
  const { t } = useTranslation();
  const { setError } = useError();
  const navigate = useNavigate();
  const { id: coopId, memberId } = useParams();
  const methods = useForm<Member>({ defaultValues: defaultMember });
  const { handleSubmit, reset } = methods;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const lastStep = 1;

  const onSubmit: SubmitHandler<Member> = (data) => {
    setLoading(true);
    data.coopId = coopId;
    (memberId
      ? OnboardingService.updateMember(data)
      : OnboardingService.saveMember(data)
    )
      .then(() => {
        setStep(lastStep);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleHome = () => {
    if (memberId) {
      navigate(`/coops/${coopId}/members/${memberId}`);
    } else {
      navigate(`/coops/${coopId}`);
    }
  };

  const handleCloseBackdrop = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (memberId) {
      OnboardingService.getMember(memberId)
        .then((res) => {
          reset(res);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [memberId, reset, setError]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <FormProvider {...methods}>
        <Box>
          {step === 0 && <OnboardingMemberInfo />}
          {step === 1 && <OnboardingConfirmation />}
        </Box>
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridTemplateAreas: '". b c"',
            width: "100%",
            mt: "auto",
            borderTop: `1px solid ${theme.palette.divider}`,
            pt: theme.spacing(2),
          })}
        >
          {step === 0 && (
            <>
              <Button
                type="submit"
                variant="contained"
                sx={{ gridColumn: "b" }}
              >
                {t("onboarding.buttons.submit")}
              </Button>
              <IconButton
                color="error"
                onClick={handleHome}
                sx={{ gridColumn: "c", justifySelf: "end" }}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
          {step === lastStep && (
            <Button
              type="button"
              variant="contained"
              onClick={handleHome}
              sx={{ gridColumn: "b" }}
            >
              {t("onboarding.buttons.home")}
            </Button>
          )}
        </Box>
      </FormProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
}

export default OnboardingMemberForm;
