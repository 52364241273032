import i18next from 'i18next';
import { useCallback, useContext } from 'react';
import LanguageContext from '../contexts/LanguageContext';

function useLang() {
  const {
    setLanguage,
    language,
    supportedLanguages,
    initialLanguage,
  } = useContext(LanguageContext);

  const onChangeLanguage = useCallback((language: string) => {
    setLanguage(language);
    i18next.changeLanguage(language);
  }, [setLanguage]);

  return {
    onChangeLanguage,
    language,
    supportedLanguages,
    initialLanguage,
  };
}

export default useLang;