import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AuthContextProvider } from './contexts/AuthContext';
import { LanguageContextProvider } from './contexts/LanguageContext';
import Router from './Router';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ErrorContextProvider } from './contexts/ErrorContext';

const supportedLanguages = ['fr', 'ff', 'en'];

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: supportedLanguages.reduce((a, v) => ({ ...a, [v]: { translation: require(`./i18n/${v}.json`) } }), {}),
    fallbackLng: supportedLanguages[0],
    load: 'languageOnly',
    debug: false
  });

function App() {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <ErrorContextProvider>
          <AuthContextProvider initialUser={sessionStorage.getItem('user')}>
            <LanguageContextProvider supportedLanguages={supportedLanguages} initialLanguage={i18next.languages[0]}>
              <Router />
            </LanguageContextProvider>
          </AuthContextProvider>
        </ErrorContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
