import { useContext } from 'react';
import ErrorContext from '../contexts/ErrorContext';

function useError() {
  const {
    setError,
    error
  } = useContext(ErrorContext);

  return {
    setError,
    error
  };
}

export default useError;