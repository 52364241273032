import ApiService from './Api';

class Authenticate {

  requestOtp(email: string): Promise<{ ok: boolean }> {
    return ApiService
      .post(
        '/auth/otp',
        { email }
      );
  }

  login(email: string, otp: string): Promise<{ email: string, accessToken: string }> {
    return ApiService
      .post(
        '/auth/login',
        { email, otp }
      );
  }

}

export default new Authenticate();
