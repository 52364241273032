import { useState, createContext, ReactElement } from 'react';

interface Context {
  setError: (error: string | null) => void;
  error: string | null;
}

const ErrorContext = createContext({} as Context);

interface ErrorContextProviderProps {
  children: ReactElement | ReactElement[];
}

function ErrorContextProvider({ children }: ErrorContextProviderProps) {
  const [error, setError] = useState<string | null>(null);
  
  const context: Context = {
    setError,
    error
  };

  return (
    <ErrorContext.Provider value={context}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
export { ErrorContextProvider };
