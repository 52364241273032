import { useState, createContext, ReactElement } from 'react';

interface Context {
  isAuthenticated: boolean;
  setUser: (user: string | null) => void;
  user: string | null;
}

const AuthContext = createContext({} as Context);

interface AuthContextProviderProps {
  initialUser: string | null;
  children: ReactElement | ReactElement[];
}

function AuthContextProvider({ initialUser, children }: AuthContextProviderProps) {
  const [user, setUser] = useState<string | null>(initialUser);
  
  const context: Context = {
    isAuthenticated: user !== null,
    // isAuthenticated: true,

    setUser,
    user
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export { AuthContextProvider };
