import {
  Box,
  Divider,
  Fab,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { areEqual, FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { memo, useEffect, useState } from "react";
import memoize from "memoize-one";
import OnboardingService from "../../services/Onboarding";
import CoopDetails from "./Coop.details";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useError from "../../hooks/error";
import CoopSimplified from "../../models/CoopSimplifed";

const Row = memo((props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const handleClick = () => {
    data.handleClick(index);
  };

  return (
    <ListItem style={style} key={index} divider sx={{ p: 0 }}>
      <ListItemButton onClick={handleClick} sx={{ py: 0 }}>
        <ListItemText
          primary={`${data.items[index].name}`}
          secondary={`${data.items[index].district}`}
          sx={{ whiteSpace: "pre-line" }}
        />
      </ListItemButton>
    </ListItem>
  );
}, areEqual);

const createItemData = memoize((items, handleClick) => ({
  items,
  handleClick,
}));

function CoopsList() {
  const { t } = useTranslation();
  const { setError } = useError();
  const navigate = useNavigate();
  const [items, setItems] = useState<CoopSimplified[]>([]);

  useEffect(() => {
    OnboardingService.getAllCoops()
      .then((res) => {
        setItems(res);
      })
      .catch((error) => {
        setError(error);
      });
  }, [setError]);

  const handleClick = (index: number) => {
    navigate(`/coops/${items[index].id}`)
  };

  const itemData = createItemData(items, handleClick);

  const handleClickOnboarding = () => {
    navigate("/onboarding");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Typography variant="h5" align="center">
        {t("listing.title")}
      </Typography>
      <Divider sx={{ mt: (theme) => theme.spacing(2) }} />
      {items.length > 0 && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemData={itemData}
                itemSize={60}
                itemCount={items.length}
                overscanCount={5}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      )}
      {items.length === 0 && <Typography>No results...</Typography>}
      <Fab
        color="secondary"
        onClick={handleClickOnboarding}
        sx={{
          position: "absolute",
          bottom: (theme) => theme.spacing(3),
          right: (theme) => theme.spacing(3),
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}

export default CoopsList;
