import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PhoneInput from "../../../components/Phone.input";
import { Controller, useFormContext } from "react-hook-form";
import Coop from "../../../models/Coop";

interface Props {
  districts: string[];
}

function OnboardingCoopInfo({ districts }: Props) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getFieldState,
  } = useFormContext<Coop>();

  return (
    <>
      <Typography variant="body1" align="center" sx={{ fontWeight: "bold" }}>
        {t("onboarding.titles.coop")}
      </Typography>
      <Controller
        name="name"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("errors.fields.required"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.coopName")}
            sx={{ mt: 2, mb: 1 }}
            required
            error={errors?.name !== undefined}
            helperText={errors?.name?.message}
          />
        )}
      />
      <FormControl sx={{ mt: 1, mb: 1, width: "100%" }}>
        <InputLabel id="district-lable">
          {t("onboarding.fields.district")} *
        </InputLabel>
        <Controller
          name="district"
          rules={{
            required: {
              value: true,
              message: t("errors.fields.required"),
            },
          }}
          defaultValue=""
          control={control}
          render={({ field, formState: { errors } }) => (
            <Select
              fullWidth
              variant="outlined"
              labelId="district-label"
              label={`${t("onboarding.fields.district")} *`}
              {...field}
              error={errors?.district !== undefined}
              required
            >
              {districts.map((dist) => (
                <MenuItem key={dist} value={dist}>
                  {dist}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors?.district?.message && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            {errors?.district?.message}
          </FormHelperText>
        )}
      </FormControl>
      <Typography sx={{ mt: 2 }} align="center" variant="subtitle2">
        {t("onboarding.titles.contact")}
      </Typography>
      <Controller
        name="contact.firstname"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("errors.fields.required"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.firstname")}
            sx={{ mt: 1, mb: 1 }}
            required
            error={errors?.contact?.firstname !== undefined}
            helperText={errors?.contact?.firstname?.message}
          />
        )}
      />
      <Controller
        name="contact.lastname"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("errors.fields.required"),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.lastname")}
            sx={{ mt: 1, mb: 1 }}
            required
            error={errors?.contact?.lastname !== undefined}
            helperText={errors?.contact?.lastname?.message}
          />
        )}
      />
      <Controller
        name="contact.email"
        control={control}
        rules={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t("errors.fields.invalid.email"),
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            label={t("onboarding.fields.email")}
            sx={{ mt: 1, mb: 1 }}
            error={errors?.contact?.email !== undefined}
            helperText={errors?.contact?.email?.message}
          />
        )}
      />
      <PhoneInput
        name="contact.phone"
        control={control}
        error={getFieldState("contact.phone").error}
        label={t("onboarding.fields.phone")}
        required={false}
        sx={{ mt: 1, mb: 2, width: "100%" }}
      />
    </>
  );
}

export default OnboardingCoopInfo;
