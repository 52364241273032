import Coop from "../models/Coop";
import CoopSimplified from "../models/CoopSimplifed";
import Member from "../models/Member";
import MemberSimplified from "../models/MemberSimplified";
import ApiService from "./Api";

class Onboarding {
  saveCoop(coop: Coop): Promise<void> {
    return ApiService.post("/coops", coop);
  }
  
  updateCoop(coop: Coop): Promise<void> {
    return ApiService.put(`/coops/${coop.id}`, coop);
  }

  getAllCoops(): Promise<CoopSimplified[]> {
    return ApiService.get(`/coops/simplified`);
  }

  getCoop(id: string): Promise<Coop> {
    return ApiService.get(`/coops/${id}`);
  }

  deleteCoop(id: string): Promise<void> {
    return ApiService.delete(`/coops/${id}`);
  }

  saveMember(member: Member): Promise<void> {
    return ApiService.post("/members", member);
  }

  updateMember(member: Member): Promise<void> {
    return ApiService.put(`/members/${member.id}`, member);
  }

  getAllMembers(): Promise<MemberSimplified[]> {
    return ApiService.get(`/members/simplified`);
  }

  getMember(id: string): Promise<Member> {
    return ApiService.get(`/members/${id}`);
  }

  deleteMember(id: string): Promise<void> {
    return ApiService.delete(`/members/${id}`);
  }
}

export default new Onboarding();
