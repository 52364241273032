import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function OnboardingConfirmation() {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t('onboarding.texts.confirmation.coop')}</Typography>
    </>
  );
}

export default OnboardingConfirmation;